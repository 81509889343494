(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:SuccessCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('SuccessCtrl', SuccessCtrl);

  function SuccessCtrl(LoggedUser, OrderService, $translate, _) {
    var vm = this
      , user = LoggedUser.getUser()
      , data = OrderService.getSuccessData()
      , newCardOrder = '1'
      , EdenredECSselectedProductType = 'EdenredEcsSelectedProductType'
      , selectedProductType = localStorage.getItem(EdenredECSselectedProductType)
      , isComfortCardSelected = selectedProductType === 'comfort'
      , furtherSteps = []
      , authenticatedSteps = [
        {
          icon: 'email-open',
          colorClass: 'warn',
          title: $translate.instant('order.success.registered.first.title'),
          legend: $translate.instant('order.success.registered.first.legend', { email: user.email })
        },
        {
          icon: 'tv-list',
          colorClass: 'primary',
          title: $translate.instant('order.success.registered.second.title'),
          legend: $translate.instant('order.success.registered.second.legend')
        }
      ]
      , registrationSteps = [
        {
          icon: 'email-open',
          colorClass: 'warn',
          title: $translate.instant('order.success.newUser.first.title'),
          legend: $translate.instant('order.success.newUser.first.legend', { email: user.email })
        },
        {
          icon: 'truck',
          colorClass: 'primary',
          title: $translate.instant('order.success.newUser.second.title'),
          legend: $translate.instant('order.success.newUser.second.legend')
        },
        {
          icon: 'badge-check',
          colorClass: 'accent',
          title: $translate.instant('order.success.newUser.third.title'),
          legend: $translate.instant('order.success.newUser.third.legend')
        }
      ]
      , comfortCardSteps = {
        registrationSteps: [
          {
            icon: 'badge-check',
            colorClass: 'accent',
            title: $translate.instant('order.success.newUser.third.title'),
            legend: $translate.instant('order.success.newUser.third.legend')
          }
        ]
      }
      , multiWalletCardSteps = {
        newCardOrder: [
          {
            icon: 'email-open',
            title: $translate.instant('order.success.sendVerificationEmail.2.title'),
            legend: $translate.instant('order.success.sendVerificationEmail.2.legend', { email: user.email })
          },
          {
            icon: 'tv-list',
            title: $translate.instant('order.success.contractStaff.title'),
            legend: $translate.instant('order.success.contractStaff.legend')
          },
          {
            icon: 'notifications',
            title: $translate.instant('order.success.sendNotification.title'),
            legend: $translate.instant('order.success.sendNotification.legend')
          },
          {
            icon: 'card',
            title: $translate.instant('order.success.productionOfCards.title'),
            legend: $translate.instant('order.success.productionOfCards.legend')
          },
          {
            icon: 'trending-up',
            colorClass: 'primary',
            title: $translate.instant('order.success.topUpCards.title'),
            legend: $translate.instant('order.success.topUpCards.multiwallet.legend')
          },
          {
            icon: 'balance-wallet',
            colorClass: 'warn',
            title: $translate.instant('order.success.sendTransferNotification.title'),
            legend: $translate.instant('order.success.sendTransferNotification.legend')
          },
          {
            icon: 'truck',
            title: $translate.instant('order.success.delivery.title'),
            legend: $translate.instant('order.success.delivery.legend')
          }
        ],
        topUpOrder: [
          {
            icon: 'email-open',
            colorClass: 'warn',
            title: $translate.instant('order.success.sendVerificationEmail.title'),
            legend: $translate.instant('order.success.sendVerificationEmail.legend', { email: user.email })
          },
          {
            icon: 'tv-list',
            colorClass: 'primary',
            title: $translate.instant('order.success.registered.second.title'),
            legend: $translate.instant('order.success.paymentBankTransfer.legend')
          },
          {
            icon: 'trending-up',
            colorClass: 'accent',
            title: $translate.instant('order.success.registered.topUp.title'),
            legend: $translate.instant('order.success.topUp.legend')
          }
        ]
      };

    if (data && data.isAuthenticated) {
      furtherSteps = authenticatedSteps;

      if (data.orderType === newCardOrder) {
        furtherSteps.push({
          icon: 'truck',
          colorClass: 'accent',
          title: $translate.instant('order.success.registered.newCard.title'),
          legend: $translate.instant('order.success.registered.newCard.legend')
        });
      } else {
        furtherSteps.push({
          icon: 'trending-up',
          colorClass: 'accent',
          title: $translate.instant('order.success.registered.topUp.title'),
          legend: $translate.instant('order.success.registered.topUp.legend')
        });
      }
    } else {
      furtherSteps = registrationSteps;

      if (isComfortCardSelected) {
        furtherSteps = comfortCardSteps.registrationSteps;
      }
    }

    vm.isNewCardOrder = function isNewCardOrder() {
      return data && data.orderType === newCardOrder;
    };

    if (selectedProductType === 'multiWallet' && !vm.isNewCardOrder()) {
      furtherSteps = multiWalletCardSteps.topUpOrder;
    }

    if (selectedProductType === 'multiWallet' && vm.isNewCardOrder()) {
      furtherSteps = multiWalletCardSteps.newCardOrder;
    }

    vm.user = user;

    vm.hasCompleteStepProcess = function hasCompleteStepProcess() {
      return furtherSteps.length > 2;
    };

    vm.getFurtherSteps = function getFurtherSteps() {
      return furtherSteps;
    };

    vm.getSelectedProductType = function getSelectedProductType() {
      return selectedProductType;
    };

    vm.isMultiwalletCardOrder = function isMultiwalletCardOrder() {
      return selectedProductType === 'multiWallet' && vm.isNewCardOrder();
    };

    vm.isVipOrder = function isVipOrder() {
      return data && data.isVip;
    };
  }
}());
